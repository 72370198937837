var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "animate-onscroll",
          rawName: "v-animate-onscroll",
          value: _vm.animate
            ? "animate__animated animate__slideUpAndAppear animate__fast"
            : "",
          expression:
            "animate ? 'animate__animated animate__slideUpAndAppear animate__fast' : ''",
        },
      ],
      staticClass: "dx-list",
      class: _vm.classes,
    },
    [
      _c("div", { staticClass: "lg:grid lg:grid-cols-12 lg:gap-8" }, [
        _c("div", { staticClass: "text-left lg:col-span-6" }, [
          _c("h2", { staticClass: "w-full md:w-11/12" }, [
            _c(
              "span",
              {
                staticClass:
                  "text-3xl md:text-4xl tracking-tight font-bold text-black-500",
              },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.contentElement.header) +
                    "\n                    "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: " relative mt-0 max-w-none mx-0 lg:col-span-6 lg:flex",
          },
          [
            _c(
              "div",
              { staticClass: "flex flex-col  mt-6 lg:mt-0" },
              [
                _c(
                  "ul",
                  { staticClass: "ml-6 lg:ml-8", attrs: { role: "list" } },
                  _vm._l(_vm.contentElement.list, function (item, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        staticClass: "py-0 pb-3 text-xl lg:text-2xl font-bold",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.label) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("dx-links", {
                  staticClass: "mt-4 lg:mt-0",
                  attrs: { outline: true, links: _vm.contentElement.links },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }