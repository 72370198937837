// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../Common/Icon/Assets/bullet.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../Common/Icon/Assets/bullet-sm.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dx-list li[data-v-503c8a40]{list-style-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-padding-start:.5ch;padding-inline-start:.5ch}@media (max-width:1023px){.dx-list li[data-v-503c8a40]{list-style-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");-webkit-padding-start:.3ch;padding-inline-start:.3ch}}", "",{"version":3,"sources":["webpack://src/Component/ContentElement/Dialocx/ContentBlock/List/List.scss"],"names":[],"mappings":"AAAA,6BAA6B,wDAA+D,CAAC,0BAA0B,CAAC,yBAAyB,CAAC,0BAA0B,6BAA6B,wDAAkE,CAAC,0BAA0B,CAAC,yBAAyB,CAAC","sourcesContent":[".dx-list li[data-v-503c8a40]{list-style-image:url(../../../../Common/Icon/Assets/bullet.svg);-webkit-padding-start:.5ch;padding-inline-start:.5ch}@media (max-width:1023px){.dx-list li[data-v-503c8a40]{list-style-image:url(../../../../Common/Icon/Assets/bullet-sm.svg);-webkit-padding-start:.3ch;padding-inline-start:.3ch}}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
