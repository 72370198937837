






























import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DxLinks from "../../../../Common/Links/Links.vue";

@Component({
    name: 'dx-list',
    components: {DxLinks}
})
export default class List extends Vue
{
    @Prop() readonly context: ContentElementContext;
    @Prop({default: true}) readonly animate: boolean;

    _contentElement: any;

    created()
    {
        // console.log('[List] _contentElement' , this.context.data.getAll());
        this._contentElement = this.context.data.getAll();
    };

    get contentElement()
    {
        return this._contentElement
    }

    get classes()
    {
        let classes = '';
        if (this._contentElement.spacer)
        {
            classes += this._contentElement.spacer.margin + ' ' + this._contentElement.spacer.padding;
        }

        if (this._contentElement.background)
        {
            classes += ' ' + this._contentElement.background;
        }

        classes += ' dx--container';

        return classes;
    }
};
